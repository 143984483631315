/* Basic styling */
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .login-title img {
    width: 100px;
    margin-bottom: 20px;
  }
  
  .login-option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .login-option img.icon {
    width: 50px;
    margin-bottom: 10px;
  }
  
  .login-option p {
    margin: 10px 0;
  }
  
  .btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn:hover {
    background-color: #b3182e !important;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .login {
      padding: 10px;
    }
  
    .login-title img {
      width: 80px;
      margin-bottom: 15px;
    }
  
    .login-option img.icon {
      width: 40px;
      margin-bottom: 8px;
    }
  
    .btn {
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .login-title img {
      width: 60px;
      margin-bottom: 10px;
    }
  
    .login-option img.icon {
      width: 30px;
      margin-bottom: 5px;
    }
  
    .btn {
      padding: 6px 12px;
    }
  }
  