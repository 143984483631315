.search-results {
  position: absolute;
  width: 100%;
  height: 85vh;
  overflow: auto;
  top: calc(100% + -1rem);
  background-color: #f9fafb;
  padding: 2rem 3.5rem;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  scrollbar-width: none;
  left: 0;
}

.search-results::-webkit-scrollbar {
  display: none;
}

.search-results h3 {
  font-size: 1.2rem;
  width: min-content;
  margin-bottom: 1em;
  border-bottom: 2px solid rgb(116, 31, 31);
  color: black;
}

.search-results p {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.534);
  margin-bottom: 1em;
  color: black;
}

.search-results p:hover {
  /* text-decoration: underline; */
}

@media (max-width: 800px) {
  .search-results {
    position: fixed;
    inset: 4rem 1rem 1rem;
    width: initial;
  }
}
