@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&family=Roboto:wght@300;400&display=swap");

:root {
  --primary-400: #ffffff;
  --primary-500: #ffffff;
  --primary-600: #ffffff;
}

:root:has(.app.dark-blue-tint) {
  --primary-400: #0b0c27;
  --primary-500: #191a35;
  --primary-600: #2b2c44;
}

.app.dark-blue-tint {
  --primary-400: #0b0c27;
  --primary-500: #191a35;
  --primary-600: #2b2c44;
}

:root:has(.app.dark) {
  --primary-400: #1f1f25;
  --primary-500: #292a31;
  --primary-600: #3c3e44;
}

.app.dark {
  --primary-400: #1f1f25;
  --primary-500: #292a31;
  --primary-600: #3c3e44;
}

:root:has(.app.dark-contrast) {
  --primary-400: #010001;
  --primary-500: #18191d;
  --primary-600: #2d2f35;
}

.app.dark-contrast {
  --primary-400: #010001;
  --primary-500: #18191d;
  --primary-600: #2d2f35;
}

.app {
  background-color: var(--primary-400);
  isolation: isolate;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition:
    color 0.5s ease-in,
    background 0.5s ease-in,
    background-color 0.5s ease-in;
  /* color: black !important; */
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background-color: #3c3e44;
}

*::-webkit-scrollbar-thumb {
  border: 2px solid #3c3e44;
  border-radius: 100vmax;
  background-color: #1f1f25;
}

.topZ {
  z-index: 99 !important;
}
:root {
  font-size: calc(1rem - 2px);
  color: white;
}

img {
  max-width: 100%;
  display: block;
}

button,
input,
textarea {
  border: none;
  outline: none;
  font-family: inherit;
  background: none;
}

button,
a {
  cursor: pointer;
  text-decoration: none !important;
  color: inherit;
}

.roundimage {
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
}

.container {
  max-width: 1500px;
  min-height: 100vh;
  padding-inline: 2rem;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

button:disabled {
  cursor: not-allowed;
}

input::placeholder {
  font-size: 0.9rem;
}

.green {
  position: relative;
}

.sidebar {
  position: fixed;
  display: none;
  max-width: 400px;
  opacity: 0;
  z-index: 999;
  border-radius: 10px;
  top: 100px;
  bottom: 10px;
  left: 0;
  right: 1rem;
  transform: translateX(-20%);
  pointer-events: none;
}

.green::after {
  content: "";
  position: absolute;
  width: 30%;
  aspect-ratio: 1;
  background-color: rgb(21, 214, 31);
  border-radius: 50%;
  right: 0;
  bottom: 0;
}

.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: "";
  position: absolute;
  inset: -2px;
  border-radius: inherit;
  z-index: -1;
  background: linear-gradient(145deg, var(--primary-600), transparent);
}

@media (max-width: 1000px) {
  .container {
    padding-inline: 0.5rem;
  }
}

@media (max-width: 800px) {
  .container {
    max-width: 500px;
  }
  .sidebar {
    display: block;
    visibility: hidden;
    transition: all 0.2s linear;
  }
  .visible {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    box-shadow: 0 10px 100px rgb(0, 0, 0);
    transition:
      opacity 0.2s linear,
      transform 0.2s linear;
  }
}

.email {
  position: relative;
}

.email-datalist {
  position: absolute;
  background-color: var(--primary-400);
  display: grid;
  border-radius: 10px;
  inset: 80% 0 auto 0;
  max-height: 12rem;
  overflow: auto;
}

.email-datalist option {
  padding-inline: 1rem;
  display: block;
  height: 3rem;
  display: grid;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s linear;
  font-size: 0.8rem;
}

.email-datalist option:hover {
  background-color: var(--primary-600);
}

.loadingBox {
  position: relative;
  background-color: var(--primary-600);
  overflow: hidden;
}

.loadingBox::after {
  position: absolute;
  content: "";
  height: 500%;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  background: var(--primary-400);
  filter: blur(50px);
  left: -50%;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    left: 150%;
  }
}
