.createpost {
  background-color: var(--primary-500);
  padding: 2rem 1rem;
  border-radius: 10px;
}

.createpost textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  resize: none;
  color: white;
  font-size: 1rem;
  background-color: var(--primary-600);
  border-radius: 10px;
  padding: 1rem;
}
.caption {
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
  background-color: var(--primary-600);
  color: white;
}
.createpost button,
label div {
  background-color: rgba(255, 255, 255, 0.158);
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
}

input[type="file"] {
  display: none;
}

label div img,
.createpost button img {
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-45%, -50%);
}

label div {
  cursor: pointer;
}

.btns {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  width: max-content;
  margin-left: auto;
}

.uploaded-image {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.uploaded-image img {
  width: 100%;
}

.uploaded-image .close-icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background-color: var(--primary-500);
  height: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

@media (max-width: 800px) {
  .createpost {
    padding: 1rem;
  }
}

.location {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
  background-color: var(--primary-600);
  color: white;
}

.courses {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
  background-color: var(--primary-600);
  color: white;
}

.d-flex {
  display: flex;
  gap: 1rem;
}

.w-50 {
  width: 50%;
}

.left-text {
  text-align: left;
  padding-top: 0.7rem;
  color: rgba(255, 255, 255, 0.158);
}

.onoffswitch {
  position: relative;
  width: 84px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  padding-top: 0.5rem;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.158);
  border-radius: 20px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 12px;
  color: black;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "Donate";
  padding-left: 6px;
  background-color: #043010;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "Sell";
  padding-right: 6px;
  background-color: #b3182e;
  color: #ffffff;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 12px;
  margin: 5px;
  background: #ffffff;
  position: absolute;
  top: 0.5rem;
  bottom: 0;
  right: 62px;
  border: 2px solid #ffffff;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.amount {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
  background-color: var(--primary-600);
  color: white;
}

.donation-option {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.donation-text {
  text-align: left;
  color: rgba(255, 255, 255, 0.158);
}

.date {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
  background-color: var(--primary-600);
  color: white;
}

.tag {
  padding: 4px 8px;
  background-color: var(--primary-600);
  border-radius: 8px;
  font-size: 14px;
  margin: 0.3rem;
  display: inline-block;
}

/* tag on hover display hand icon */
.tag:hover {
  cursor: pointer;
}

.tag-div-border {
  border: 1px solid;
  margin-top: 0.7rem;
  border-radius: 8px;
  border-color: var(--primary-600);
}

.d-block {
  display: block;
}

.tag i {
  margin-right: 4px;
  font-style: normal;
  color: rgb(255 255 255 / 49%);
}

.tag-text {
  padding-bottom: 0.7rem;
  padding-left: 0.7rem;
}

.h-50 {
  height: 50px;
}

.header-logo {
  display: flex;
  justify-content: space-around;
  padding-bottom: 0.7rem;
}

/* according css */
details {
  border: 1px solid #b3182e;
  padding: 0.75em 0.75em 0;
  margin-top: 10px;
  /* box-shadow:0 0 20px #b3182e; */
}

summary {
  /* font-weight: bold; */
  margin: -0.75em -0.75em 0;
  padding: 0.75em;
  /* background-color: #b3182e; */
  color: #b3182e;
}

details[open] {
  padding: 0.75em;
  border-bottom: 1px solid #b3182e;
}

details[open] summary {
  border-bottom: 1px solid #b3182e;
  margin-bottom: 10px;
}
.accordion-button {
  background-color: rgb(255 255 255 / 49%) !important;
}

.border-none {
  border: none !important;
}

.post-header {
  color: var(--primary-600);
}
