.appbar {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: sticky;
  height: 7rem;
  top: 0rem;
  z-index: 5;
  background-color: var(--primary-400);
}

.appbar form {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-500);
  width: 50%;
  max-width: 500px;
  border-radius: 10px;
  gap: 1rem;
  position: relative;
}

.appbar form button {
  width: 20px;
  cursor: pointer;
}

.appbar form input {
  color: white;
  width: 85%;
}

.appbar__profile {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
}

.appbar__profile__dp {
  width: 40px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
}
.appbar__profile a {
  height: 40px;
}

.appbar__profile__logout {
  width: 22px;
  margin-bottom: 5px;
}

.appbar .close {
  transform: scale(1.5);
  aspect-ratio: 1;
}

.hamburger {
  display: none;
  width: 22px;
}

.home-icon {
  width: 30px;
  transform: translateY(-2px);
}

.hamburger > img {
  width: 100%;
}

.appbar__profile .chat {
  height: 85%;
}

.appbar a {
  display: flex;
  align-items: center;
}

.appbar .login-btn {
  background-color: var(--primary-600);
  padding: 0.75rem 1.5rem;
  color: white;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .appbar {
    gap: 0.5rem;
  }
  .appbar form {
    padding: 0.75rem;
    flex: 1;
  }
  .hamburger {
    display: flex;
    align-items: center;
  }
  .appbar__profile {
    gap: 1rem;
  }
  .home-icon {
    display: none;
  }
}
